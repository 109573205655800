import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { requestAdditionalFeedback } from "../actions/feedback";
import { setFeedback } from "../store/feedbackSlice";
import { FeedbackData } from "@validation-workshop/types";

export const End = () => {
  const [started, setStarted] = useState(false);
  const { t } = useTranslation("translation");
  const feedback = useAppSelector((state) => state.feedback);
  const [questionCount] = useState(feedback.data.length);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const feedbackResult = useQuery({
    queryKey: ["feedback-" + questionCount],
    queryFn: requestAdditionalFeedback,
    enabled: started,
  });

  useEffect(() => {
    if (feedbackResult.data) {
      dispatch(setFeedback(feedbackResult.data as FeedbackData[]));
    }
  }, [feedbackResult.data, dispatch]);

  // Redirect user after logging in
  useEffect(() => {
    if (feedbackResult.isSuccess) {
      if (!feedbackResult.data?.length) {
        console.error("User has no assigned questions");
        return;
      }

      const lastFilledFeedback = feedbackResult.data.findIndex(
        (data) => data.rating === undefined
      );

      if (lastFilledFeedback === -1) {
        navigate("/end");
        return;
      }

      navigate(
        "/question/" + feedbackResult.data[lastFilledFeedback].questionId
      );
    }
  }, [feedbackResult.isSuccess, feedbackResult.data, navigate]);

  const handleBack = useCallback(async () => {
    navigate("/question/" + feedback.data[feedback.data.length - 1].questionId);
  }, [feedback.data, navigate]);

  // Redirect user if not started
  useEffect(() => {
    if (feedback.data.length === 0) {
      navigate("/");
    }
  }, [feedback, navigate]);

  return (
    <Container maxWidth="sm">
      <Paper
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mt: 1 }}>
          {t("end.thanks")}
        </Typography>
        <Typography sx={{ m: 3, mt: 1 }}>
          {t("end.text")}
          <br />
          <br />
          {t("end.text2")}
        </Typography>
        {feedbackResult.isLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ mb: 1 }} />
          </Box>
        )}
        {!feedbackResult.isLoading && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button variant="contained" onClick={handleBack}>
              {t("question.back")}
            </Button>
            <Button onClick={() => setStarted(true)} variant="contained">
              {t("question.next")}
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );
};
