import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import EmoticonRating from "../components/EmoticonRating";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setFeedback } from "../store/feedbackSlice";
import { FeedbackData } from "@validation-workshop/types";
import { ActorCard } from "../components/ActorCard";
import { submitFeedback } from "../actions/feedback";
import { retrieveQuestion } from "../actions/question";
import { useTranslation } from "react-i18next";

export const Question = () => {
  const [rating, setRating] = useState<number | null>(null);
  const [additionalFeedback, setAdditionalFeedback] = useState<string>("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>();
  const [currentFeedbackData, setCurrentFeedbackData] =
    useState<FeedbackData>();
  const { t } = useTranslation("translation");
  const { questionId } = useParams();
  const feedback = useAppSelector((state) => state.feedback);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Set current question index, current feedback data and rating
  useEffect(() => {
    if (feedback.data.length === 0) {
      console.error("User has no assigned questions");
      return;
    }

    const currentQuestion = feedback.data.find(
      (question) => question.questionId === questionId
    );

    if (!currentQuestion) {
      console.error("Question not found");
      return;
    }

    setCurrentFeedbackData(currentQuestion);
    setCurrentQuestionIndex(feedback.data.indexOf(currentQuestion));
    setRating(currentQuestion.rating || null);
    setAdditionalFeedback(currentQuestion.additionalFeedback || "");
  }, [feedback, questionId]);

  const question = useQuery({
    queryKey: ["question" + questionId],
    queryFn: async () => {
      if (!questionId) {
        return;
      }

      return await retrieveQuestion(questionId);
    },
  });

  const handleSubmit = useCallback(async () => {
    if (!currentFeedbackData) {
      return;
    }

    const newFeedback = {
      ...currentFeedbackData,
      rating: rating || undefined,
      additionalFeedback: additionalFeedback,
    };

    const newFeedbacks = feedback.data.map((feedback) => {
      if (feedback.questionId === newFeedback.questionId) {
        return newFeedback;
      }

      return feedback;
    });

    await submitFeedback(newFeedback);
    dispatch(setFeedback(newFeedbacks));
  }, [
    dispatch,
    feedback.data,
    rating,
    additionalFeedback,
    currentFeedbackData,
  ]);

  const handleBack = useCallback(async () => {
    if (currentQuestionIndex === undefined) {
      return;
    }

    await handleSubmit();

    navigate("/question/" + feedback.data[currentQuestionIndex - 1].questionId);
  }, [currentQuestionIndex, feedback.data, navigate, handleSubmit]);

  const handleNext = useCallback(async () => {
    if (currentQuestionIndex === undefined) {
      return;
    }

    await handleSubmit();

    if (currentQuestionIndex + 1 >= feedback.data.length) {
      navigate("/end");
      return;
    }

    navigate("/question/" + feedback.data[currentQuestionIndex + 1].questionId);
  }, [currentQuestionIndex, feedback.data, navigate, handleSubmit]);

  const handleRatingChange = (
    event: React.ChangeEvent<{}>,
    value: number | null
  ) => {
    setRating(value);
  };

  const handleAdditionalFeedbackChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdditionalFeedback(event.target.value);
  };

  // Redirect user if no questions are assigned
  useEffect(() => {
    if (feedback.data.length === 0) {
      console.warn("No questions assigned, redirecting to /");
      navigate("/");
    }
  }, [feedback, navigate]);

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Paper sx={{ p: 1 }}>
          <Typography variant="h4">
            {t("question.question")} {(currentQuestionIndex || 0) + 1}{" "}
            {t("question.of")} {feedback.data.length}
          </Typography>
        </Paper>
      </Box>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Grid container spacing={2}>
          {question.isLoading && (
            <Grid item xs={12}>
              <Paper>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Paper>
            </Grid>
          )}
          {question.isError && (
            <Grid item columns={{ xs: 4, sm: 8, md: 12 }}>
              <Paper>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography>Failed to load content</Typography>
                </Box>
              </Paper>
            </Grid>
          )}
          {question.isSuccess &&
            question.data &&
            question.data.actorData.map((actor) => (
              <ActorCard
                key={actor.name}
                actor={actor.name}
                bestWorks={actor.bestWorks}
              />
            ))}
        </Grid>
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ m: 2 }}>
            {t("question.feedback")}
          </Typography>
          <Divider flexItem variant="middle" />
          <Box sx={{ m: 2, display: "flex", flexDirection: "column" }}>
            <EmoticonRating value={rating} onChange={handleRatingChange} />
            <TextField
              sx={{ mt: 2 }}
              label={t("question.additional-feedback")}
              value={additionalFeedback}
              onChange={handleAdditionalFeedbackChange}
              multiline
              rows={4}
            />
          </Box>
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              disabled={!currentQuestionIndex}
              onClick={handleBack}
            >
              {t("question.back")}
            </Button>
            <Button
              variant="contained"
              disabled={rating === null}
              onClick={handleNext}
            >
              {t("question.next")}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};
