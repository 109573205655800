import { FeedbackData } from "@validation-workshop/types";
import { REACT_APP_API_URL } from "../constants";

export const submitFeedback = async (feedbackData: FeedbackData) => {
  const response = await fetch(
    new URL(`/feedback/${feedbackData.feedbackId}`, REACT_APP_API_URL),
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...feedbackData,
      }),
    }
  );

  const data = await response.json();

  if (!response.ok) {
    console.error(data);
    throw new Error(data.errors);
  }
};

export const retrieveFeedback = async (): Promise<FeedbackData[]> => {
  const response = await fetch(new URL(`/feedback`, REACT_APP_API_URL), {
    credentials: "include",
  });

  const data = await response.json();

  if (!response.ok) {
    console.error(data);
    throw new Error(data.errors);
  }

  return data;
};

export const requestAdditionalFeedback = async (): Promise<FeedbackData[]> => {
  const response = await fetch(new URL(`/feedback/new`, REACT_APP_API_URL), {
    credentials: "include",
  });

  const data = await response.json();

  if (!response.ok) {
    console.error(data);
    throw new Error(data.errors);
  }

  return data;
};
