import { QuestionData } from "@validation-workshop/types";
import { REACT_APP_API_URL } from "../constants";

export const retrieveQuestion = async (
  questionId: string
): Promise<QuestionData> => {
  const response = await fetch(
    new URL(`/question/${questionId}`, REACT_APP_API_URL)
  );

  const data = await response.json();

  if (!response.ok) {
    console.error(data);
    throw new Error(data.errors);
  }

  return data;
};
