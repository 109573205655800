import React from "react";
import { styled } from "@mui/material/styles";
import { IconContainerProps, Rating, RatingProps } from "@mui/material";
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentVerySatisfied,
  SentimentNeutral,
} from "@mui/icons-material";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: (
      <SentimentVeryDissatisfied
        sx={{ width: "100%", height: "100%" }}
        color="error"
        fontSize="inherit"
      />
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: (
      <SentimentDissatisfied
        sx={{ width: "100%", height: "100%" }}
        color="error"
        fontSize="inherit"
      />
    ),
    label: "Dissatisfied",
  },
  3: {
    icon: (
      <SentimentNeutral
        sx={{ width: "100%", height: "100%" }}
        color="warning"
        fontSize="inherit"
      />
    ),
    label: "Neutral",
  },
  4: {
    icon: (
      <SentimentSatisfied
        sx={{ width: "100%", height: "100%" }}
        color="success"
        fontSize="inherit"
      />
    ),
    label: "Satisfied",
  },
  5: {
    icon: (
      <SentimentVerySatisfied
        sx={{ width: "100%", height: "100%" }}
        color="success"
        fontSize="inherit"
      />
    ),
    label: "Very Satisfied",
  },
};

const IconContainer = (props: IconContainerProps) => {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
};

export const EmoticonRating = (props: RatingProps) => {
  return (
    <StyledRating
      // defaultValue={3}
      IconContainerComponent={IconContainer}
      getLabelText={(value: number) => customIcons[value].label}
      highlightSelectedOnly
      {...props}
    />
  );
};

export default EmoticonRating;
