import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FeedbackData } from "@validation-workshop/types";

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: { data: [] } as { data: FeedbackData[] },
  reducers: {
    setFeedback: (state, action: PayloadAction<FeedbackData[]>) => {
      state.data = [...action.payload];
    },
  },
});

export const { setFeedback } = feedbackSlice.actions;
export default feedbackSlice.reducer;
