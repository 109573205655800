import React from "react";
import { ButtonBase, Divider, Grid, Paper, Typography } from "@mui/material";
import { WorkData } from "@validation-workshop/types";

export const ActorCard = ({
  actor,
  bestWorks,
}: {
  actor: string;
  bestWorks: WorkData[];
}) => {
  return (
    <Grid item xs={12} sm={12} md={6}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 1,
        }}
      >
        <Typography variant="h4">{actor}</Typography>
        <Divider flexItem variant="middle" />

        <Grid container spacing={2}>
          {bestWorks.map((work) => (
            <Grid item key={work.micrioId} xs={6}>
              <ButtonBase
                sx={{ width: "100%" }}
                focusRipple
                onClick={() => {
                  let handle = window.open(
                    `https://www.rijksmuseum.nl/nl/collectie/${work.workId}`
                    // "_blank"
                  );

                  if (!handle) {
                    return;
                  }

                  handle.blur();
                  window.focus();
                }}
              >
                <img
                  width="100%"
                  src={`https://iiif.micr.io/${work.micrioId}/full/300,/0/default.webp`}
                  alt=""
                />
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ActorCard;
