import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { setFeedback } from "../store/feedbackSlice";
import { FeedbackData } from "@validation-workshop/types";
import { useAppDispatch } from "../store/hooks";
import { useNavigate } from "react-router-dom";
import { retrieveFeedback } from "../actions/feedback";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../components/LanguageSelector";
import ExampleImg from "../assets/example.png";
import { REACT_APP_DOMAIN } from "../constants";

export const Start = () => {
  const [started, setStarted] = useState(false);
  const { t } = useTranslation("translation");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const feedbackResult = useQuery({
    queryKey: ["feedback"],
    queryFn: async () => {
      // Set a cookie with the current datetime to identify the user
      if (!document.cookie.includes("date=")) {
        document.cookie =
          "date=" +
          new Date().getTime() +
          `; SameSite=None; Secure; path=/; Domain=${REACT_APP_DOMAIN}`;
      }

      return await retrieveFeedback();
    },
    enabled: started,
  });

  useEffect(() => {
    if (feedbackResult.data) {
      dispatch(setFeedback(feedbackResult.data as FeedbackData[]));
    }
  }, [feedbackResult.data, dispatch]);

  // Redirect user after logging in
  useEffect(() => {
    if (feedbackResult.isSuccess) {
      if (!feedbackResult.data?.length) {
        console.error("User has no assigned questions");
        return;
      }

      const lastFilledFeedback = feedbackResult.data.findIndex(
        (data) => data.rating === undefined
      );

      if (lastFilledFeedback === -1) {
        navigate("/end");
        return;
      }

      navigate(
        "/question/" + feedbackResult.data[lastFilledFeedback].questionId
      );
    }
  }, [feedbackResult.isSuccess, feedbackResult.data, navigate]);

  return (
    <Container maxWidth="sm">
      <Paper
        sx={{
          m: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          sx={{ mt: 1, textAlign: "center" }}
        >
          {t("title")}
        </Typography>

        <Box sx={{ m: 1 }}>
          <LanguageSelector />
        </Box>

        <Typography sx={{ m: 3, mt: 1 }}>
          {t("introduction.thanks")}
          <br />
          <br />
          {t("introduction.content")}
          <br />
          <br />
          {t("introduction.content2")}
          <br />
          <br />
          {t("introduction.example")}
          <br />
          <br />
          <Box
            component="img"
            sx={{ width: "100%" }}
            src={ExampleImg}
            alt="Example"
          />
          <br />
          <br />
          {t("introduction.time")}
          <br />
          <br />
          {t("introduction.consent")}
        </Typography>
        {feedbackResult.isLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ mb: 1 }} />
          </Box>
        )}
        {!feedbackResult.isLoading && (
          <Box sx={{ mt: 1 }}>
            <Button
              fullWidth
              onClick={() => setStarted(true)}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("start")}
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );
};
