import { GB, NL } from "country-flag-icons/react/3x2";
import { IconButton, SvgIcon } from "@mui/material";
import { useTranslation } from "react-i18next";

export const LanguageSelector = () => {
  const { i18n } = useTranslation("translation");

  return (
    <div>
      <IconButton onClick={() => i18n.changeLanguage("nl")}>
        <SvgIcon>
          <NL title="Nederlands" />
        </SvgIcon>
      </IconButton>
      <IconButton onClick={() => i18n.changeLanguage("en")}>
        <SvgIcon>
          <GB title="English" />
        </SvgIcon>
      </IconButton>
    </div>
  );
};
