import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Start } from "./routes/Start";
import { End } from "./routes/End";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Question } from "./routes/Question";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import store from "./store";
import i18n from "./i18n";

document.title = i18n.t("title");

const theme = createTheme({
  palette: {
    background: {
      default: "#21262b",
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Start />,
  },
  {
    path: "/question/:questionId",
    element: <Question />,
  },
  {
    path: "/end",
    element: <End />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <RouterProvider router={router} />
        </QueryClientProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
